<template>
    <div>
        <p class="text">{{ $t('faq.emby.step5.whenYouChooseCountry') }}</p>
        <div class="screenshot">
            <img class="step-image" src="../assets/screen-5.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
